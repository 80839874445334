import { requestService } from "./requestService";
import { authService } from "./authService";

const api_url = process.env.VUE_APP_API_URL;
const timeout = 60000; //ms

function make_post_request(section, param, intento = 0) {
  return requestService.make_post_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("POST", e);
    return error_result;
  });
}

function make_get_request(section, intento = 0) {
  return requestService.make_get_request(api_url, timeout, section, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

function make_get_param_request(section, intento = 0) {
  return requestService.make_get_param_request(api_url, timeout, section, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

function make_delete_request(section, param, intento = 0) {
  return requestService.make_delete_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}

function make_put_request(section, param, intento = 0) {
  return requestService.make_put_request(api_url, timeout, section, param, intento).catch((e) => {
    const error_result = requestService.handle_error("GET", e);
    return error_result;
  });
}
/*
function make_auth_post_request(section,param,intento = 0) {  
  return requestService.make_post_request(
    api_url,
    timeout,
    getAccessToken(),
    section,
    param,
    intento
  ).catch((e) => {    
    const error_result = requestService.handle_error("POST",e)    
    
    return handleError("POST", true, section, param, error_result)   
  })  
}

function make_auth_get_request(section,intento = 0) {    
  return requestService.make_get_request(
    api_url,
    timeout,
    getAccessToken(),
    section,
    intento
  ).catch((e) => {
    //console.log(e)
    const error_result = requestService.handle_error("GET",e)      
    return handleError("GET", true, section, {}, error_result)   
  }) 
}



function handleError(method, auth, section, param, error_result, intento = 0){

  console.log("Error akeronService: ")
  console.log(error_result)
  if(error_result.code == 408 || error_result.code == 410)
      return retryRequest(method, auth, section, param)

  var response = null
  if(error_result.code == 403 && response != null && response.code == 'token_not_valid'){
    return requestNewToken().then(result => {            
      if(result){
        return retryRequest(method, auth, section, param, intento)
      }else{
        return { status: false, msg: 'Error refreshing the Market token.', code: error_result.code, exception: error_result.exception }
      }
    })
  } 
  return response
}

function retryRequest(method, auth, section, param , intento = 0){
  if(intento <= 1) { 
    if(method == 'POST'){
      if(auth)
        return make_auth_post_request(section,param,(intento+1)) 
      return make_post_request(section,param,(intento+1)) 
    }else if(method == 'GET'){
      if(auth)
        return make_auth_get_request(section,(intento+1)) 
      return make_get_request(section,(intento+1))
    }
  }
}

function requestNewToken(){  
  return akeronService.make_post_request('customers/v1/refresh/',{'refresh':getRefreshToken()}).then(response => {                     
    if(response.code == 'response' || response.code == 'token_not_valid'){
      console.log("Logout:"+response.detail)
      authService.logOut()      
      return false
    }

    if(response.access != undefined){
      localStorage.setItem('access_fn',response.access)
      console.log("NEW access_fn:"+response.access)
      console.log(response)
      return true
    }

    console.log(response)
    return false
  }).catch((e) => {
      console.log("Error akeronService: requestNewToken")
      console.log(e)  
      return false               
  })    
}

function getAccessToken(){
  const access_fn = localStorage.getItem('access_fn')     
  if(access_fn != null || access_fn != undefined)
    return access_fn
  else{
    console.clear()
    console.log("No access_fn")
    console.log(access_fn)
    localStorage.clear()
    window.location.reload()
  }
}

function getRefreshToken(){
  const refresh_fn = localStorage.getItem('refresh_fn')     
  if(refresh_fn != null || refresh_fn != undefined)
    return refresh_fn
  else{
    console.clear()
    console.log("No refresh_fn")
    console.log(refresh_fn)
    localStorage.clear()
    window.location.reload()
  }
}
*/

export const akeronService = {
  make_post_request,
  make_get_request,
  make_delete_request,
  make_put_request,
  make_get_param_request,
  //make_auth_post_request,
  //make_auth_get_request,
  //requestNewToken,
};
