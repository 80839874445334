<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-6">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre')" name="nameDriver" v-model="item.nameDriver" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nameDriver") }}</span>
                <v-text-field data-vv-as="documento" label="Documento*" name="cuit" v-model="item.cuit" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("cuit") }}</span>
                <v-text-field data-vv-as="direccion" :label="$t('Direccion')" name="driverAddress" v-model="item.driverAddress" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("driverAddress") }}</span>
                <v-text-field data-vv-as="telefono" :label="$t('Telefono')" name="driverTelephone" v-model="item.driverTelephone" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("driverTelephone") }}</span>
                <v-text-field data-vv-as="email" label="Email*" name="driverEmail" v-model="item.driverEmail" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("driverEmail") }}</span>

                <v-combobox
                  v-model="item.vehicle"
                  data-vv-as="vehiculo"
                  name="idVehicleType"
                  item-text="patent"
                  item-value="id"
                  :items="vehiculos"
                  :label="$t('Vehiculo')"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("idVehicleType") }}</span>

                <v-checkbox :label="$t('Activo')" v-model="item.isActive"></v-checkbox>
              </div>
              <div class="col-md-6">
                <v-checkbox :label="$t('Habilitado_app')" v-model="item.enabledAPP"></v-checkbox>

                <v-text-field
                  prepend-icon="mdi-account"
                  data-vv-as="usuario"
                  :label="$t('Usuario')"
                  sublabel="Recomendación: DNI"
                  name="userAPP"
                  v-model="item.userAPP"
                  v-validate="'required'"
                  :disabled="!item.enabledAPP || isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("userAPP") }}</span>

                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  label="Password"
                  data-vv-as="password"
                  name="passAPP"
                  v-model="item.passAPP"
                  prepend-icon="mdi-lock"
                  :disabled="!item.enabledAPP || isViewModal()"
                />
                <span class="red--text">{{ errors.first("passAPP") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { vehiculoService } from "@/libs/ws/vehiculoService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      vehiculos: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadVehiculos();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadVehiculos() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehiculos = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      if (this.item.id > 0) {
        this.service
          .update(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_actualizo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      } else {
        this.service
          .create(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          });
      }
    },
  },
};
</script>
