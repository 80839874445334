<template>
  <div>
    <vue-html2pdf
      :manual-pagination="true"
      pdf-content-width="100%"
      ref="html2Pdf"
      :preview-modal="false"
      :pdf-quality="2"
      :enable-download="true"
      :htmlToPdfOptions="htmlToPdfOptions"
      @hasDownloaded="$emit('closeReporte')"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <div class="row">
            <div class="col">
              <h5 style="margin-left:15px;margin-top:10px;font-size: 11px">Fecha:&nbsp;{{ getFecha(ruta.date) }}&nbsp;| {{ ruta.date.substr(11, 8) }}&nbsp; &nbsp;-&nbsp;Página N°&nbsp;1</h5>
            </div>
          </div>
          <hr class="mt-1 mb-3" />
          <div class="row">
            <div style="width:12%">
              <h3 style="margin-left:30px;font-size: 11px" class="pt-1 mb-0">
                HOJA DE RUTA N°
              </h3>
              <h3 style="margin-left:30px;font-size: 30px" class="mb-2">
                {{ ruta.numTrip }}
              </h3>
            </div>
            <table style="width:88%;font-size: 11px">
              <tr>
                <th style="padding-left:30px">Chofer:&nbsp;{{ ruta.driver.nameDriver }}</th>
                <th>Cuit:&nbsp;{{ ruta.driver.cuit }}</th>
                <th>Tel:&nbsp;{{ ruta.driver.driverTelephone }}</th>
                <th>Vehiculo:&nbsp;{{ ruta.vehicle.patent }}</th>
              </tr>
              <tr>
                <th style="padding-left:30px">Tipo de Vehiculo:&nbsp;{{ ruta.vehicle.type.type }}</th>
                <th>Tipo de Carga:&nbsp;{{ ruta.typeCharge.typeCharge }}</th>
                <th>Transporte:&nbsp;{{ ruta.transport.nameTransport }}</th>
                <th>Origen:&nbsp;{{ ruta.origen.dirOrigen }}</th>
              </tr>
              <tr>
                <th style="padding-left:30px">Zona:&nbsp;{{ ruta.zone.zone }}</th>
                <th>
                  Custodia:&nbsp;
                  {{ ruta.companyCustody != null ? ruta.companyCustody.nameCompany : "" }}
                </th>
                <th>Carga Máx:&nbsp;{{ ruta.vehicle.capacity }} M<sup>3</sup></th>
                <th>Modulos:&nbsp;{{ ruta.quantityModules }}</th>
              </tr>
            </table>
          </div>

          <hr class="mt-3 mb-2" />

          <table class="headerReportes" style="width:100%;font-size: 10px">
            <tr>
              <th class="text-center" style="width:3%">
                N°
              </th>
              <th style="width:10%;font-size: 11px">Cliente</th>
              <th style="width:17%;font-size: 11px">Sub cliente</th>
              <th style="width:8%;font-size: 11px">Remito</th>
              <th style="width:4%;font-size: 11px;text-align:center">
                Pall
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                Bul
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">Kgs</th>
              <th style="width:3%;font-size: 11px;text-align:center">M<sup>3</sup></th>
              <th style="width:3%;font-size: 11px;text-align:center">Ra</th>
              <th style="width:3%;font-size: 11px;text-align:center">Tam</th>
              <th style="width:3%;font-size: 11px;text-align:center">Bo</th>
              <th style="width:22%;font-size: 11px">Dirección</th>
              <th style="width:7%;font-size: 11px">Localidad</th>
              <th style="width:11%;font-size: 11px">Observaciones</th>
            </tr>
          </table>
        </section>
        <section class="pdf-item" v-for="(arrRemito, index) in remitos" :key="index">
          <table style="width:100%;font-size: 10px" class="tablaReportes">
            <tr class="tr" v-for="remito in arrRemito" :key="remito.id">
              <td class="text-center" style="padding-left:5px; width:3%;font-size: 10px">
                {{ remito.orden }}
              </td>
              <td style=" width:10%;font-size: 10px">
                {{ remito.client.nameClient }}
              </td>
              <td style=" width:17%;font-size: 10px">
                {{ remito.subClient.nameSubClient }}
              </td>
              <td style=" width:8%;font-size: 11px">{{ remito.remito }}</td>
              <td style=" width:4%;font-size: 11px;text-align:center;">
                {{ remito.pallets }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.packages }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.kg }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.cubicMeters }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.rack }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.tambores }}
              </td>
              <td style=" width:3%;font-size: 11px;text-align:center">
                {{ remito.bobina }}
              </td>
              <td style=" width:22%;font-size: 11px">
                {{ remito.subClient.subClientAddress }}
              </td>
              <td style=" width:7%;font-size: 11px">
                {{ remito.subClient.locality.name }}
              </td>
              <!-- falta nombre -->
              <td style=" width:11%;font-size: 11px">
                {{ remito.observation }}
              </td>
            </tr>
          </table>

          <div class="html2pdf__page-break" v-if="!(index == remitos.length - 1)" />

          <div v-if="!(index == remitos.length - 1)">
            <div class="row">
              <div class="col">
                <h3 style="margin-top:10px;margin-left:30px;font-size: 12px">Hoja de ruta N°&nbsp;{{ ruta.numTrip }}</h3>
              </div>
              <div class="col">
                <h5 style="margin-top:10px;font-size: 11px">Fecha:&nbsp;{{ getFecha(ruta.date) }}</h5>
              </div>
              <div class="col">
                <h5 style="margin-top:10px;font-size: 11px">Página N° {{ index + 2 }}</h5>
              </div>
            </div>
            <hr class="mt-1 mb-2" />
          </div>

          <table style="width:100%" class="mt-1" v-if="index == remitos.length - 1">
            <tr>
              <th style="padding-left:30px ; width:3%"></th>
              <th style="width:10%"></th>
              <th style="width:17%"></th>
              <th style="width:8%;font-size: 11px">TOTALES</th>
              <th style="width:3%;font-size: 11px;text-align:center;padding:15px">
                {{ getTotalPallets() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalBultos() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalKg() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalM3() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalRack() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalTambores() }}
              </th>
              <th style="width:3%;font-size: 11px;text-align:center">
                {{ getTotalBobina() }}
              </th>
              <th style=" width:22%"></th>
              <th style=" width:7%"></th>
              <th style=" width:11%"></th>
            </tr>
          </table>
        </section>

        <section class="pdf-item">
          <div class="footer" style="padding-left:15px ; padding-right: 15px;">
            <div class="row pl-2 pr-2">
              <div class="col-md-12 footer-text mt-4 mb-2">
                <p style="font-size: 8px">
                  SOLO DEBEN CONFORMARSE LOS REMITOS (PUEDEN TENER MAS DE UNA PAGINA). LAS FACTURAS QUEDAN EN EL CLIENTE. EN LOS REMITOS DESPACHADOS POR EXPRESOS DEBEN FIGURAR LA HORA DE ENTREGA Y
                  CANTIDAD DE BULTOS ENTREGADOS. DICHA CANTIDAD DEBE COINCIDIR CON LO DECLARADO POR {{ companyName }} LOS CONFORMES DEBEN SER RENDIDOS A {{ companyName }} SI O SI A MAS TARDAR AL OTRO
                  DIA "URGENTE" AVISAR POR DEMORAS DE MÁS DE 20 MIN. ANTE CUALQUIER PROBLEMA AVISAR URGENTE A TRAFICO.
                </p>
              </div>
            </div>

            <div class="row pl-2 pr-2">
              <div class="col-md-2  pt-0 pb-0">
                <p style="font-size: 11px;" class="mb-1">
                  Vigilancia&nbsp;
                </p>
                <table style="font-size:11px">
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Arribo:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Ingreso:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Egreso:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Intervino:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                </table>
              </div>
              <div class="col-md-2  pt-0 pb-0">
                <p style="font-size: 11px;" class="mb-0">Observaciones:</p>
                <p style="font-size: 11px" class="mb-0">
                  {{ ruta.observation1 }}
                </p>
                <p style="font-size: 11px" class="mb-0">
                  {{ ruta.observation2 }}
                </p>
              </div>
              <div class="col-md-2  pt-0 pb-0" style="display:flex;justify-content:flex-end;flex-direction: column" v-for="(adicionalArray, index) in adicionales" :key="index">
                <table style="font-size:11px">
                  <tr v-for="(aditional, index) in adicionalArray" :key="index">
                    <td>
                      <p class="mb-1">{{ $t(aditional.tipo) }}:</p>
                    </td>
                    <td>
                      <p class="mb-1">{{ aditional.quantity }}</p>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="col-md-2 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion&nbsp;del&nbsp;chofer
                </p>
              </div>
              <div class="col-md-3 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion del&nbsp;responsable logístico
                </p>
              </div>
              <div class="col-md-3 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion&nbsp;del&nbsp; responsable depósito
                </p>
              </div>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import Constant from "@/constants/";
export default {
  components: { VueHtml2pdf },
  props: {
    ruta: Object,
    reportGenerate: Boolean,
    closeReporte: Function,
    remitosSeleccionados: Array,
  },

  mounted() {
    this.companyName = cryptoUtils.decrypt(localStorage.getItem("company"), Constant.SECRET_PHRASE);
  },
  data() {
    return {
      companyName: "",
      remitos: [],
      adicionales: [],
      htmlToPdfOptions: {
        margin: [10, 0, 0, 0],
        filename: "",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 4,
          scrollY: 0,
          scrollX: 0,
        },
        jsPDF: {
          orientation: "landscape",
          format: "a4",
        },
      },
    };
  },
  watch: {
    reportGenerate() {
      if (this.reportGenerate) {
        this.ruta.delivered = this.remitosSeleccionados;
        this.remitosSeleccionados.sort(function(a, b) {
          if (a.orden > b.orden) {
            return 1;
          }
        });

        this.adicionales = this.sliceIntoChunks(this.ruta.aditionals, 5);
        this.remitos = this.sliceIntoChunks(this.remitosSeleccionados, 9);
        this.htmlToPdfOptions.filename = "Ruta Nro " + this.ruta.numTrip;
        this.generateReport();
      }
    },
  },
  methods: {
    generateReport() {
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 2000);
    },

    getTotalPallets() {
      var pallets = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.pallets != 0) {
          pallets = pallets + elemento.pallets;
        }
      });

      if (pallets % 1 == 0) {
        return pallets;
      } else {
        return pallets.toFixed(1);
      }
    },
    getTotalBultos() {
      var bultos = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.packages != 0) {
          bultos = bultos + elemento.packages;
        }
      });
      return bultos;
    },
    getTotalKg() {
      var kg = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.kg != 0) {
          kg = kg + elemento.kg;
        }
      });
      if (kg % 1 == 0) {
        return kg;
      } else {
        return kg.toFixed(1);
      }
    },
    getTotalM3() {
      var total = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.cubicMeters != 0) {
          total = total + elemento.cubicMeters;
        }
      });
      if (total % 1 == 0) {
        return total;
      } else {
        return total.toFixed(1);
      }
    },
    getTotalRack() {
      var total = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.rack != 0) {
          total = total + elemento.rack;
        }
      });
      if (total % 1 == 0) {
        return total;
      } else {
        return total.toFixed(1);
      }
    },
    getTotalBobina() {
      var total = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.bobina != 0) {
          total = total + elemento.bobina;
        }
      });
      if (total % 1 == 0) {
        return total;
      } else {
        return total.toFixed(1);
      }
    },
    getTotalTambores() {
      var total = 0;
      this.ruta.delivered.forEach(function(elemento) {
        if (elemento.tambores != 0) {
          total = total + elemento.tambores;
        }
      });
      if (total % 1 == 0) {
        return total;
      } else {
        return total.toFixed(1);
      }
    },
    getFecha(date) {
      return new Date(date).toLocaleDateString();
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    },
  },
};
</script>
